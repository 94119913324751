import React from 'react'
import Hadder from '../components/Hadder'
import Footer from '../components/Footer'
import room1 from "../assets/Home/room1.JPG"
import room2 from "../assets/Home/room2.jpeg"
import room3 from "../assets/Home/room3.JPG"
import room4 from "../assets/Home/room4.JPG"
import ban5 from "../assets/Banner/ban5.JPG"
import g1 from "../assets/Home/room4.JPG"
import "../css/style.css"
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import rom from "../assets/Home/room4/rom.JPG"
import { MetaTags } from 'react-meta-tags';
import bed from "../assets/icon/bed.png"
import person from "../assets/icon/person.png"
import wifi from "../assets/icon/wifi.png"
const Rooms = () => {
  return (
    <div>
        <Hadder></Hadder>
        <MetaTags>
        <title>Resort Near Palghar | Kinara Beach Resort</title>
        <meta title="Resort Near Palghar | Kinara Beach Resort" />
        <meta name="description" content="Unwind at our exceptional resort near Palghar, where nature's beauty meets luxury. Enjoy a serene escape with comfortable accommodations, breathtaking views, and modern amenities for an unforgettable getaway." />
        <meta name="keywords" content="beach-resort-family-palghar, beach-resort-near-palghar,beach-resort-in-palghar,resort-in-palghar,resort-near-palghar,top-beach-resort-in-palghar,deluxe-cottage-in-palghar,best-cottage-for-family-in-palghar,cottage-for-family-in-palghar,cottage-for-couple-in-palghar" />
        <link rel="canonical" href="https://kinararesort.in/resort-near-palghar" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Resort Near Palghar | Kinara Beach Resort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://kinararesort.in/" />
        <meta property="og:description" content="Unwind at our exceptional resort near Palghar, where nature's beauty meets luxury. Enjoy a serene escape with comfortable accommodations, breathtaking views, and modern amenities for an unforgettable getaway." />
        <meta property="og:image" content="https://kinararesort.in/static/media/logo.146c55d2a549f20e2963.png" />
      </MetaTags>
        <section className="page-header bg--cover second-banner" style={{backgroundImage:`url(${ ban5})`}}>
        <div className="container">
          <div className="page-header__content text-center">
            <h1 style={{color:"white"}}>Resort Near Palghar</h1>
           
             
          
          </div>
        </div>
      </section>
      <section className="room padding-top padding-bottom">
  <div className="container">
    <div className="room__wrapper">
      <div className="row g-5">
      <Fade left>
        <div className="col-12">
          <div className="room__item room__item--style4">
            <div className="room__item-inner">
              <div className="room__item-thumb">
                <img src={room1} alt="New Dulex Room Kinara Resort-Couple" />
              </div>
              <div className="room__item-content">
                <div className="room__item-header">
                  <div className="room__item-name">
                   
                    <h3>
                      <a style={{textAlign:"left"}}>Spring Field Villa</a>
                    </h3>
                  
                  </div>
                  <div className="room__item-price">
                 
                    
                  </div>
                </div>
                <p><div className="row">
                  <div className="col-md-4">
                  <div style={{textAlign:"left"}}>
                     
                      <li>Air Conditioning</li>
                    
                      <li>Balcony</li>
                      <li>Electric Kettle</li>
                      </div>
                  </div>
                  <div className="col-md-4">
                <div style={{textAlign:"left"}}>
                      <li>Free Wi-Fi</li>
                      
                      <li>Pool</li>
                      <li>Garden View</li>
                      </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{textAlign:"left"}}>
                  <li>Beach View</li>
                  <li>Bathroom</li>
                  <li>Private Entrance</li>
                  </div>
                  </div>
                  </div></p>
                <div className="room__item-body">
                  
                  <ul className="room__feature room_icon">
                    <li className="room__feature-item">
                      <div className="room__feature-icon">
                        <img
                          src={person}
                          alt="4 Person"
                          style={{width:"30px"}}
                        />
                      </div>
                      <div className="room__feature-text">
                        <p>4 Person</p>
                      </div>
                    </li>
                    <li className="room__feature-item">
                      <div className="room__feature-icon">
                        <img
                          src={bed}
                          alt="1 Double bed"
                          style={{width:"30px"}}
                        />
                      </div>
                      <div className="room__feature-text">
                        <p>1 Double bed</p>
                      </div>
                    </li>
                    <li className="room__feature-item">
                      
                    </li>
                    
                  </ul>
                  
                  <a href="#" className="custom-btn">
                    <span><Link to="/deluxe-cottage-in-palghar">Enquiry Now</Link></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Fade>
        <Fade right>
        <div className="col-12">
          <div className="room__item room__item--style4 room__item--reverse">
            <div className="room__item-inner">
              <div className="room__item-thumb">
                <img src={room2} alt="Superior Family Room Wooden Cottage Kinara Resort" />
              </div>
              <div className="room__item-content">
                <div className="room__item-header">
                  <div className="room__item-name">
                   
                    <h3>
                      <a style={{textAlign:"left"}}>Sunny Side Villa</a>
                    </h3>
                    
                  </div>
                  <div className="room__item-price">
                 
                    
                  </div>
                </div>
                <p><div className="row">
                  <div className="col-md-4">
                  <div style={{textAlign:"left"}}>
                     
                      <li>Air Conditioning</li>
                    
                      <li>Balcony</li>
                      <li>Electric Kettle</li>
                      </div>
                  </div>
                  <div className="col-md-4">
                <div style={{textAlign:"left"}}>
                      <li>Free Wi-Fi</li>
                      
                      <li>Pool</li>
                      <li>Garden View</li>
                      </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{textAlign:"left"}}>
                  <li>Beach View</li>
                  <li>Bathroom</li>
                  <li>Private Entrance</li>
                  </div>
                  </div>
                  </div></p>
                <div className="room__item-body">
                 
                  <ul className="room__feature room_icon">
                    <li className="room__feature-item">
                      <div className="room__feature-icon">
                        <img
                          src={person}
                          alt="4 Person"
                          style={{width:"30px"}}
                        />
                      </div>
                      <div className="room__feature-text">
                        <p>4 Person</p>
                      </div>
                    </li>
                    <li className="room__feature-item">
                      
                    </li>
                    <li className="room__feature-item">
                    <div className="room__feature-icon">
                        <img
                          src={bed}
                          alt="1 Double bed
                          & 1 Bunk bed"
                          style={{width:"30px"}}
                        />

                      </div>
                      <div className="room__feature-text">
                        <p>1 Double bed
                          & 1 Bunk bed
                        </p>
                      </div>
                    </li>
                   
                  </ul>
                  
                  <a href="#" className="custom-btn">
                    <span><Link to="/best-cottage-for-family-in-palghar">Enquiry Now</Link></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Fade>
        <Fade left>
        <div className="col-12">
          <div className="room__item room__item--style4 ">
            <div className="room__item-inner">
              <div className="room__item-thumb">
                <img src={room3} alt="Superior Family Room -Wooden Cottage Kinara Resort" />
              </div>
              <div className="room__item-content">
                <div className="room__item-header">
                  <div className="room__item-name">
                   
                    <h3>
                      <a style={{textAlign:"left"}}>Kaashi Villa</a>
                    </h3>
                    
                  </div>
                  <div className="room__item-price">
              
                    
                  </div>
                </div>
                <p><div className="row">
                  <div className="col-md-4">
                  <div style={{textAlign:"left"}}>
                     
                      <li>Air Conditioning</li>
                    
                      <li>Balcony</li>
                      <li>Electric Kettle</li>
                      </div>
                  </div>
                  <div className="col-md-4">
                <div style={{textAlign:"left"}}>
                      <li>Free Wi-Fi</li>
                      
                      <li>Pool</li>
                      <li>Garden View</li>
                      </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{textAlign:"left"}}>
                  <li>Beach View</li>
                  <li>Bathroom</li>
                  <li>Private Entrance</li>
                  </div>
                  </div>
                  </div></p>
                <div className="room__item-body">
                 
                  <ul className="room__feature room_icon">
                    <li className="room__feature-item">
                      <div className="room__feature-icon">
                        <img
                          src={person}
                          alt="5 Person"
                          style={{width:"30px"}}
                        />
                      </div>
                      <div className="room__feature-text">
                        <p>5 Person</p>
                      </div>
                    </li>
                    
                    <li className="room__feature-item">
                    <div className="room__feature-icon">
                        <img
                          src={bed}
                          alt="1 Duble bed 1 Bunk bed 1 Single bed"
                          style={{width:"30px"}}
                        />
                      </div>
                      <div className="room__feature-text">
                        <p>1 Duble bed <br/> 1 Bunk bed<br/> 1 Single bed</p>
                        <p></p>
                   
                      </div>
                    </li>
                  
                  </ul>
                  <a href="#" className="custom-btn">
                    <span><Link to="/cottage-for-family-in-palghar">Enquiry Now</Link></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Fade>
        <Fade right>
        <div className="col-12" style={{paddingBottom:"50PX"}}>
          <div className="room__item room__item--style4 room__item--reverse">
            <div className="room__item-inner">
              <div className="room__item-thumb">
                <img src={g1} alt="Couple Room Wooden Cottage Kinara Resort" />
              </div>
              <div className="room__item-content">
                <div className="room__item-header">
                  <div className="room__item-name">
                   
                    <h3>
                      <a style={{textAlign:"left"}}>Kinara villa</a>
                    </h3>
                   
                  </div>
                  <div className="room__item-price">
                    
                    
                  </div>
                </div>
                <p><div className="row">
                  <div className="col-md-4">
                  <div style={{textAlign:"left"}}>
                     
                      <li>Air Conditioning</li>
                    
                      <li>Balcony</li>
                      <li>Electric Kettle</li>
                      </div>
                  </div>
                  <div className="col-md-4">
                <div style={{textAlign:"left"}}>
                      <li>Free Wi-Fi</li>
                      
                      <li>Pool</li>
                      <li>Garden View</li>
                      </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{textAlign:"left"}}>
                  <li>Beach View</li>
                  <li>Bathroom</li>
                  <li>Private Entrance</li>
                  </div>
                  </div>
                  </div></p>
                <div className="room__item-body">
              
                  <ul className="room__feature room_icon">
                    <li className="room__feature-item">
                      <div className="room__feature-icon">
                        <img
                          src={person}
                          alt="2 Person"
                          style={{width:"30px"}}
                        />
                      </div>
                      <div className="room__feature-text">
                        <p>2 Person</p>
                      </div>
                    </li>
                    <li className="room__feature-item">
                      <div className="room__feature-icon">
                        <img
                          src={bed}
                          alt="feature icon"
                          style={{width:"30px"}}
                        />
                      </div>
                      <div className="room__feature-text">
                        <p>1 Duble</p>
                      </div>
                    </li>
                    
                  </ul>
                  <a href="#" className="custom-btn">
                    <span><Link to="/cottage-for-couple-in-palghar">Enquiry Now</Link></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Fade>
      </div>
     
    </div>
  </div>
</section>


      <Footer></Footer>
    </div>
  )
}

export default Rooms
