
import './App.css';
import Hadder from './components/Hadder';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Aboutus from './pages/Aboutus';
import Home from "./pages/Home"
import Contact from './pages/Content';
import Service from './pages/Service';
import Gallery from './pages/Gallery';
import Rooms from './pages/Rooms';
import Roomdetail from './pages/Roomdetail';
import Roomdetail2 from './pages/Roomdetail2';
import Roomdetail3 from './pages/Roomdetail3';
import Roomdetail4 from './pages/Roomdetail4';
import "./css/Buttions.css"
import Whatapp  from "./components/whatapp"
import Error from './components/Error';
function App() {
  return (
    <div className="App">
    <BrowserRouter> 
      <Whatapp></Whatapp>
      <Routes>

     <Route path='/' element={<Home/>}></Route>
     <Route path='/beach-resort-near-palghar' element={<Aboutus/>}></Route>
     <Route path='/beach-resort-in-palghar' element={<Service/>}></Route>
     <Route path='/resort-in-palghar' element={<Gallery/>}></Route>
     <Route path='/resort-near-palghar' element={<Rooms/>}></Route>
     <Route path='/top-beach-resort-in-palghar' element={<Contact/>}></Route>
     <Route path='/deluxe-cottage-in-palghar' element={<Roomdetail/>}></Route>
     <Route path='/best-cottage-for-family-in-palghar' element={<Roomdetail2/>}></Route>
     <Route path='/cottage-for-family-in-palghar' element={<Roomdetail3/>}></Route>
     <Route path='/cottage-for-couple-in-palghar' element={<Roomdetail4/>}></Route>
     <Route path='/*' element={<Error/>}></Route>
      </Routes>

    </BrowserRouter>
   
    </div>
  );
}

export default App;
