import React from 'react'
import "../css/Aboutus.css"
import "../css/style.css"
import Footer from '../components/Footer'
import Hadder from '../components/Hadder'
import a1 from "../assets/a1.png"
import a2 from "../assets/a2.png"
import a3 from "../assets/a3.png"
import NumberCounter from 'number-counter';
import Aboutusi1 from "../assets/abouti1.png"
import Aboutusi2 from "../assets/abouti2.png"
import Accordion from 'react-bootstrap/Accordion';
import p1 from "../assets/P1.png"
import S1 from "../assets/S1.png"
import WIFI from "../assets/wifi.png"
import R1 from "../assets/R1.png"
import ban1 from "../assets/Banner/ban1.jpeg"
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import room4 from "../assets/Home/rom4.jpeg"
import ban4 from "../assets/Banner/ban4.JPG"
import ban2 from "../assets/Banner/ban2.jpeg"
import ban5 from "../assets/Banner/ban5.JPG"
import ban6 from "../assets/Banner/ban6.JPG"
import ban7 from "../assets/Banner/ban7.JPG"
import ban8 from "../assets/Banner/ban8.JPG"
import ban9 from "../assets/Banner/ban9.JPG"
import Aboutussecond from "../assets/Aboutussecond.JPG"
import g1 from "../assets/Home/Room1/G1.JPG"
import G2 from "../assets/Home/Room1/G2.JPG"
import G3 from "../assets/Home/Room1/G3.JPG"
import G4 from "../assets/Home/Room1/G4.JPG"
import G5 from "../assets/Home/Room1/G5.JPG"
import { MetaTags } from 'react-meta-tags';
import bann3 from "../assets/Home/bann3.jpeg"
const Aboutus = () => {
  return (
    <div>
        <Hadder></Hadder>
        <MetaTags>
        <title>Beach Resort near Palghar | Kinara Beach Resort</title>
        <meta title=" Beach Resort near Palghar | Kinara Beach Resort" />
        <meta name="description" content="Escape to serenity at our Beach Resort near Palghar. Enjoy sun, sand & relaxation with stunning ocean views. Book your perfect getaway today!" />
        <meta name="keywords" content="beach-resort-family-palghar, beach-resort-near-palghar,beach-resort-in-palghar,resort-in-palghar,resort-near-palghar,top-beach-resort-in-palghar,deluxe-cottage-in-palghar,best-cottage-for-family-in-palghar,cottage-for-family-in-palghar,cottage-for-couple-in-palghar" />
        <link rel="canonical" href="https://kinararesort.in/beach-resort-near-palghar" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Beach Resort near Palghar | Kinara Beach Resort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://kinararesort.in/" />
        <meta property="og:description" content="Escape to serenity at our Beach Resort near Palghar. Enjoy sun, sand & relaxation with stunning ocean views. Book your perfect getaway today!" />
        <meta property="og:image" content="https://kinararesort.in/static/media/logo.146c55d2a549f20e2963.png" />
      </MetaTags>
   <section className="page-header bg--cover second-banner" style={{backgroundImage:`url(${ ban5})`}}>
        <div className="container">
          <div className="page-header__content text-center">
            <h1 style={{color:"white"}}>Beach Resort near Palghar</h1>
           
             
          
          </div>
        </div>
      </section>
      <section className="container sethome"> 
<div className="sethome">
        <div className="row">
        <Fade left>
        <h2 className="homeabout disp2">About us</h2>
          <div className="col-md-6">
          
            <img  className="imaghei" src={bann3}/>
          </div>
          </Fade>
          <Fade right>
          <div className="col-md-6">
            <h2 className="homeabout disp1">About us</h2>
            <p className="peratext">
            Beach Resort near Palghar is the epitome of serenity and relaxation, offering a perfect retreat for families seeking a memorable getaway.




Beach Resort near Palghar understands the importance of creating unforgettable family moments. Our resort is thoughtfully designed to cater to the diverse needs of families, ensuring that everyone, from the youngest to the eldest, has an exceptional experience.




Beach Resort near Palghar takes pride in offering a wide range of comfortable and spacious accommodations that suit families of all sizes. From cozy beachfront cottages to luxurious suites, every room is designed with your comfort in mind, providing stunning views of the ocean or our lush tropical gardens.




Beach Resort near Palghar is committed to family-friendly hospitality that extends to facilities and activities. Our beachfront restaurant serves a delectable array of local and international cuisine, catering to all taste buds.
            </p>
            {/* <div className="butoonleft">
            <a href="#" className="custom-btn">
                    <span>Explore Room</span>
                  </a>
                  </div> */}
          </div>
          </Fade>
        </div>
        </div>
      </section>
      {/* <section className="about padding-top padding-bottom about--shape">
        <div className="container">
          <div className="about__wrapper">
            <div className="row flex-lg-row-reverse align-items-center g-5">
              <div className="col-lg-6">
                <div className="about__content aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000}>
                  <div className="common-header">
                    <p className="subtitle testalignleft">Welcome to Kinara Resort</p>
                    <h2 className='testalignleft'>Our Hotel has been present for over 20 years.</h2>
                  </div>
                  <p className='testalignleft'>Continually productize compelling quality for packed in business consulting elated
                    productize compelling quality for all packed in with all growth from quality elated
                    Setting up to website and creating pages.
                  </p>
                  <p className='testalignleft '>Continually productize compelling quality for packed in business consulting elated
                    productize compelling quality for all packed in with all growth from quality elated
                    Setting up to website and creating pages.
                  </p>
                  <ul className="funfact__list my-4">
                    <li className="funfact__item">
                      <div className="funfact__item-icon">
                        <img src={a1} className='imagesize' alt="funfact icon" />
                      </div>
                      <div className="funfact__item-content">
                        <h3><span><NumberCounter end={100} delay={4}/></span></h3>
                        <p>Available Rooms</p>
                      </div>
                    </li>
                    <li className="funfact__item">
                      <div className="funfact__item-icon">
                        <img src={a2} className='imagesize' alt="funfact icon" />
                      </div>
                      <div className="funfact__item-content">
                        <h3><span><NumberCounter end={100} delay={4}/></span></h3>
                        <p>Staff Members</p>
                      </div>
                    </li>
                    <li className="funfact__item">
                      <div className="funfact__item-icon">
                        <img src={a3} className='imagesize' alt="funfact icon" />
                      </div>
                      <div className="funfact__item-content">
                        <h3><span><NumberCounter end={100} delay={4}/></span></h3>
                        <p>Served Clients</p>
                      </div>
                    </li>
                  </ul>
                  <a href="#" className="custom-btn"><span>Explore Now</span></a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about__image aos-init aos-animate" data-aos="fade-up-right" data-aos-duration={1000}>
                  <img src="https://labartisan.net/demo/tavern/assets/images/about/home3/1.png" alt="About image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="feature padding-top padding-bottom bg--section-color">
  <div className="container">
    <div
      className="section-header aos-init"
      data-aos="fade-up"
      data-aos-duration={1000}
    >
      <div className="section-header__content">
        <h2>Tavern Hotel Your Excellent Choice For Vacation.</h2>
        <p>
          Continually productize compelling quality for packed in business
          consulting elated Setting up to website and creating pages.
        </p>
      </div>
    </div>
    <div className="feature__wrapper">
      <div className="row g-4">
        <div className="col-xl-3 col-md-6">
          <div
            className="feature__item aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="feature__item-inner">
              <div className="feature__item-thumb">
                <img
                  src="	https://labartisan.net/demo/tavern/assets/images/feature/icons/1.png"
                  alt="feature icon"
                />
              </div>
              <div className="feature__item-content">
                <h5>Customer Support</h5>
                <p>24 Hours Support</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div
            className="feature__item aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={200}
          >
            <div className="feature__item-inner">
              <div className="feature__item-thumb">
                <img
                  src="	https://labartisan.net/demo/tavern/assets/images/feature/icons/2.png"
                  alt="feature icon"
                />
              </div>
              <div className="feature__item-content">
                <h5>Family Size Room</h5>
                <p>Available Room</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div
            className="feature__item aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={300}
          >
            <div className="feature__item-inner">
              <div className="feature__item-thumb">
                <img
                  src="https://labartisan.net/demo/tavern/assets/images/feature/icons/3.png"
                  alt="feature icon"
                />
              </div>
              <div className="feature__item-content">
                <h5>Conference Room</h5>
                <p>Conference Room Available</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div
            className="feature__item aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={400}
          >
            <div className="feature__item-inner">
              <div className="feature__item-thumb">
                <img
                  src="https://labartisan.net/demo/tavern/assets/images/feature/icons/4.png"
                  alt="feature icon"
                />
              </div>
              <div className="feature__item-content">
                <h5>Free Wifi Corner</h5>
                <p>Free Wifi</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-4 padding-20">
        <div className="col-xl-3 col-md-6">
          <div
            className="feature__item aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="feature__item-inner">
              <div className="feature__item-thumb">
                <img
                  src="	https://labartisan.net/demo/tavern/assets/images/feature/icons/1.png"
                  alt="feature icon"
                />
              </div>
              <div className="feature__item-content">
                <h5>Customer Support</h5>
                <p>24 Hours Support</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div
            className="feature__item aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={200}
          >
            <div className="feature__item-inner">
              <div className="feature__item-thumb">
                <img
                  src="	https://labartisan.net/demo/tavern/assets/images/feature/icons/2.png"
                  alt="feature icon"
                />
              </div>
              <div className="feature__item-content">
                <h5>Family Size Room</h5>
                <p>Available Room</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div
            className="feature__item aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={300}
          >
            <div className="feature__item-inner">
              <div className="feature__item-thumb">
                <img
                  src="https://labartisan.net/demo/tavern/assets/images/feature/icons/3.png"
                  alt="feature icon"
                />
              </div>
              <div className="feature__item-content">
                <h5>Conference Room</h5>
                <p>Conference Room Available</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div
            className="feature__item aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={400}
          >
            <div className="feature__item-inner">
              <div className="feature__item-thumb">
                <img
                  src="https://labartisan.net/demo/tavern/assets/images/feature/icons/4.png"
                  alt="feature icon"
                />
              </div>
              <div className="feature__item-content">
                <h5>Free Wifi Corner</h5>
                <p>Free Wifi</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}
 <section
  className="service padding-top padding-bottom bg--cover"
  style={{ backgroundImage: "url(assets/images/service/bg.png)" }}
>
  <div className="container">
    <div
      className="section-header aos-init aos-animate"
      data-aos="fade-up"
      data-aos-duration={1000}
    >
      <div className="section-header__content">
        <h2>Kinara resort Your Excellent Choice For Vacation.</h2>
        <p>
        Immerse yourself in the visual journey of Kinara Beach Resort through our gallery. Browse through a stunning collection of images that showcase the breathtaking beauty of our beachfront paradise, the elegance of our accommodations, the delectable culinary offerings, and the joyous moments of our guests, all encapsulating the essence of a remarkable stay with us.
        </p>
      </div>
    </div>
    <div className="service__wrapper">
      <div className="row g-4">
      <Bounce>
        <div className="col-xl-3 col-md-6">
          <div
            className="service__item2 aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="service__item2-inner">
              <div className="service__item2-thumb border10">
                <img
                  src={R1}
                  alt="Restaurant"
                  style={{width:"55px"}}
                />
              </div>
              <div className="service__item2-content">
                <h3>
                  <a >Restaurant</a>{" "}
                </h3>
              
              </div>
            </div>
          </div>
        </div>
        </Bounce>
        <Bounce>
        <div className="col-xl-3 col-md-6">
          <div
            className="service__item2 aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={100}
          >
            <div className="service__item2-inner">
              <div className="service__item2-thumb border10">
                <img
                  src={Aboutusi1}
                  alt="Beach Access"
                  style={{width:"55px"}}
                />
              </div>
              <div className="service__item2-content">
                <h3>
                  <a >Beach Access</a>{" "}
                </h3>
              
              </div>
            </div>
          </div>
        </div>
        </Bounce>
        <Bounce>
        <div className="col-xl-3 col-md-6">
          <div
            className="service__item2 aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={200}
          >
            <div className="service__item2-inner">
              <div className="service__item2-thumb border10">
                <img
                  src={S1}
                  alt="Swimming Pool"
                  style={{width:"55px"}}
                />
              </div>
              <div className="service__item2-content">
                <h3>
                  <a >Swimming Pool</a>{" "}
                </h3>
              
              </div>
            </div>
          </div>
        </div>
        </Bounce>
        <Bounce>
       
        <div className="col-xl-3 col-md-6">
          <div
            className="service__item2 aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={300}
          >
            <div className="service__item2-inner">
              <div className="service__item2-thumb border10">
                <img
                  src={Aboutusi2}
                  alt="Garden Area"
                  style={{width:"55px"}}
                />
              </div>
              <div className="service__item2-content">
                <h3>
                  <a >Garden Area</a>{" "}
                </h3>
              
              </div>
            </div>
          </div>
        </div>
        </Bounce>
        <Bounce>
        <div className="col-xl-6 col-md-6">
          <div
            className="service__item2 aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={400}
          >
            <div className="service__item2-inner">
              <div className="service__item2-thumb border10">
                <img
                  src={WIFI}
                  alt="High Speed Wifi"
                  style={{width:"55px"}}
                />
              </div>
              <div className="service__item2-content">
                <h3>
                  <a >High Speed Wifi</a>{" "}
                </h3>
              
              </div>
            </div>
          </div>
        </div>
        </Bounce>
        <Bounce>
        <div className="col-xl-6 col-md-6">
          <div
            className="service__item2 aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={500}
          >
            <div className="service__item2-inner">
              <div className="service__item2-thumb border10">
                <img
                  src={p1}
                  alt="Car Parking"
                  style={{width:"55px"}}
                />
              </div>
              <div className="service__item2-content">
                <h3>
                  <a >Car Parking</a>{" "}
                </h3>
              
              </div>
            </div>
          </div>
        </div>
        </Bounce>
      </div>
    </div>
  </div>
</section> 

<section className="gallery padding-top padding-bottom">
  <div className="container">
    <div className="section-header section-header--withnav">
      <div className="section-header__content">
        <h2 style={{textAlign:"center"}}>Kinara resort Gallery.</h2>
        <p style={{textAlign:"center"}}>
        Kinara Resort offers a tranquil escape amidst pristine natural surroundings, with luxurious accommodations and a host of recreational activities, making it the perfect destination for relaxation and adventure.
        </p>
      </div>
      {/* <div className="section-header__nav">
        <div className="common-filter">
          <button className="common-filter__btn active" data-filter="*">
            All Rooms
          </button>
          <button className="common-filter__btn" data-filter=".family">
            Family Rooms
          </button>
          <button className="common-filter__btn" data-filter=".single">
            Single Rooms
          </button>
        </div>
      </div> */}
    </div>
    <div className="gallery__wrapper">
      <div
        className="row g-4 gallery__grid gallery__grid--home2"
        style={{ position: "relative", height: "325.712px" }}
      >
        
        <div
          className="col-md-4 gallery__grid-item single"
          style={{ position: "absolute", left: 0, top: "0px" }}
        >
          <div className="gallery__grid-inner ">
            <div className="gallery__grid-image">
              <img
                src={G3}
                alt="kinara resort gallery resort"
              />
             
            </div>
          </div>
        </div>
        <div
          className="col-md-4 gallery__grid-item family"
          style={{ position: "absolute", left: "439.987px", top: "0px" }}
        >
          <div className="gallery__grid-inner">
            <div className="gallery__grid-image">
              <img
                src={G4}
                alt="best resort in palghar"
              />
              
            </div>
          </div>
        </div>
        <div
          className="col-md-4 gallery__grid-item family"
          style={{ position: "absolute", left: "879.974px", top: "0px" }}
        >
          <div className="gallery__grid-inner">
            <div className="gallery__grid-image">
              <img
                src={G5}
                alt="resort in palghar"
              />
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <div className="acorder">
  <div className="container">
    <div className="row">
    <Fade left>
      <div className="col-md-6">
<img src={Aboutussecond} alt="" />
      </div>
      </Fade>
      <Fade right>
      <div className="col-md-6">
      <Accordion defaultActiveKey="0">
     
      <Accordion.Item eventKey="0">
  
        <div style={{backgroundColor:"#e0d009",padding:"10px"}} >Privacy policy</div>
        
        <Accordion.Body>
        <Accordion defaultActiveKey="">
        <Accordion.Item eventKey="0">
        <Accordion.Header>Contacting the Property</Accordion.Header>
        <Accordion.Body>
          
        <p style={{textAlign:"justify"}}>If you want to request something specific, you can do that offer you choose your rate. once you book, wek we'll provide deatails so you can. contact the property directly </p>
        

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Children and extra Beds.</Accordion.Header>
        <Accordion.Body>
          <h2>Children policy</h2>
        <p style={{textAlign:"justify"}}>children of any age are welcome. children age 10 years & above are considered adults at this property. To see correct prices and ot occupancy information, please add number at children in your group and their ages to your search</p>
        <h2>Extra Beds</h2>
        <p style={{textAlign:"justify"}}>
Adults (lo+ years )
Extra bed upon Request ₹1500 per person, 1 per night.</p>
<p style={{textAlign:"justify"}}>Supplement are calculated automatically in the total costs and will have to be paid in total bill during your stay. There is no capacity for cots. at this property. The maximum number of extra. Beds allowed depends on the Room you choose. please check the maximum capacity for the Room you selected. All extra Beds are subject to availability.</p>
        </Accordion.Body>
      </Accordion.Item>
      
    
    </Accordion>
   
    
    
    
        </Accordion.Body>
      </Accordion.Item>
      
    </Accordion>
        </div>
        </Fade>
    </div>

  </div>
  </div>
</section>


<Footer></Footer>

    </div>
  )
}

export default Aboutus
