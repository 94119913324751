import s1 from "../assets/s1.jpg"
import s2 from "../assets/s2.jpg"
import s3 from "../assets/s3.jpg"
import s4 from "../assets/s4.jpg"
import s5 from "../assets/s5.jpg"
import s6 from "../assets/s6.webp"
import s7 from "../assets/s7.jpg"
import s8 from "../assets/s8.jpg"
import s9 from "../assets/s9.webp"
import s10 from "../assets/s10.jpg"
import ban1 from "../assets/Banner/ban1.jpeg"
import ban2 from "../assets/Banner/ban2.jpeg"
import ban3 from "../assets/Banner/ban3.JPG"
import ban4 from "../assets/Banner/ban4.JPG"
import ban5 from "../assets/Banner/ban5.JPG"
import ban6 from "../assets/Banner/ban6.JPG"
import ban7 from "../assets/Banner/ban7.JPG"
import ban8 from "../assets/Banner/ban8.JPG"
import ban9 from "../assets/Banner/ban9.JPG"
import g1 from "../assets/Home/Room1/G1.JPG"
import g2 from "../assets/Home/Room1/G2.JPG"
import g3 from "../assets/Home/Room1/G3.JPG"
import g4 from "../assets/Home/Room1/G4.JPG"
import g5 from "../assets/Home/Room1/G5.JPG"
import g6 from "../assets/Home/Room1/G6.JPG"
import g7 from "../assets/Home/Room1/G7.JPG"

export const photos = [
    {
      src:ban1,
      width: 4,
      height: 3
    },
    {
      src: ban2,
      width: 1,
      height: 1
    },
    {
      src:ban3,
      width: 3,
      height: 4
    },
    {
      src: ban4,
      width: 3,
      height: 4
    },
    {
      src: ban5,
      width: 3,
      height: 4
    },
    {
      src:g1,
      width: 4,
      height: 3
    },
    {
      src:g2,
      width: 3,
      height: 4
    },
    {
      src:g3,
      width: 4,
      height: 3
    },
    {
      src: g4,
      width: 4,
      height: 3
    },
    {
      src: g5,
      width: 4,
      height: 3
    },
    {
      src: g6,
      width: 4,
      height: 3
    },
    {
      src: g7,
      width: 4,
      height: 3
    },
  ];
  