import React from "react";
import "../css/Home.css";
import "../css/style.css";
import home from "../assets/h1.jpg";
import Hadder from "../components/Hadder";
import Footer from "../components/Footer";
import Testimonial from "../components/testimonal";
import h1 from "../assets/h1.png";
import h2 from "../assets/h2.png";
import h3 from "../assets/h3.png";
import h4 from "../assets/h4.png";
import h5 from "../assets/h5.png";
import h6 from "../assets/h6.png";
import h7 from "../assets/h7.png";
import h8 from "../assets/h8.png";
import beach from "../assets/beach.png";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Slider from "../components/slider";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import ban1 from "../assets/Banner/ban1.jpeg";
import room1 from "../assets/Home/room1.JPG";
import room2 from "../assets/Home/room2.jpeg";
import room3 from "../assets/Home/room3.JPG";
import room4 from "../assets/Home/room4.JPG";
import ban4 from "../assets/Banner/ban4.JPG";
import ban8 from "../assets/Banner/ban8.JPG";
import { MetaTags } from "react-meta-tags";
import bann1 from "../assets/Home/bann1.jpeg";
import bann2 from "../assets/Home/bann2.jpeg";
const Home = () => {
  return (
    <div>
      <Hadder></Hadder>
      <Slider></Slider>
      <MetaTags>
        <title>Beach Resort For Family in Palghar | Kinara Beach Resort</title>
        <meta title=" Beach Resort For Family in Palghar | Kinara Beach Resort" />
        <meta
          name="description"
          content="Beach Resort For Family in Palghar Kinara Beach Resort get the perfect family getaway. Enjoy sun, sand, & smiles with our family-friendly amenities."
        />
        <meta
          name="keywords"
          content="beach-resort-family-palghar, beach-resort-near-palghar,beach-resort-in-palghar,resort-in-palghar,resort-near-palghar,top-beach-resort-in-palghar,deluxe-cottage-in-palghar,best-cottage-for-family-in-palghar,cottage-for-family-in-palghar,cottage-for-couple-in-palghar"
        />
        <link
          rel="canonical"
          href="https://kinararesort.in/beach-resort-family-palghar"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content=" Beach Resort For Family in Palghar | Kinara Beach Resort"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://kinararesort.in/" />
        <meta
          property="og:description"
          content="Beach Resort For Family in Palghar Kinara Beach Resort get the perfect family getaway. Enjoy sun, sand, & smiles with our family-friendly amenities."
        />
        <meta
          property="og:image"
          content="https://kinararesort.in/static/media/logo.146c55d2a549f20e2963.png"
        />
      </MetaTags>

      <section className="container sethome">
        <div className="sethome">
          <div className="row">
            <Fade left>
              <h2 className="homeabout disp2">About us</h2>
              <div className="col-md-6">
                <img className="imaghei" src={bann1} />
              </div>
            </Fade>
            <Fade right>
              <div className="col-md-6">
                <h2 className="homeabout disp1">About us</h2>
                <p className="peratext">
                  Beach Resort for Family in Palghar is the epitome of serenity
                  and relaxation, as it offers a perfect retreat for families
                  seeking a memorable getaway and offers a wide range of
                  comfortable and spacious accommodations that suit families of
                  all sizes. From cozy beachfront cottages to luxurious suites,
                  every room is designed with your comfort in mind, providing
                  stunning views of the ocean or our lush tropical gardens.
                </p>
                <div className="butoonleft">
                  <a href="#" className="custom-btn">
                    <span>
                      <Link to="/beach-resort-near-palghar">Read More</Link>
                    </span>
                  </a>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section className="room room--popular padding-top padding-bottom">
        <div className="container">
          <div
            className="section-header aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="section-header__content">
              <h2>Kinara Resort Best Rooms &amp; Suites.</h2>
              <p>
                Discover our exquisite range of rooms and suites designed to
                elevate your stay to a new level of opulence and relaxation at
                Kinara Beach Resort.
              </p>
            </div>
          </div>
          <div
            className="room__wrapper aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="row g-4">
              <Fade right>
                <div className="col-lg-4 col-md-6 ">
                  <div className="room__item room__item--style2">
                    <div className="room__item-inner">
                      <div className="room__item-thumb">
                        <img
                          src={room1}
                          alt="New Dulex Room Kinara Resort-Couple Room "
                        />
                      </div>
                      <div className="room__item-content">
                        <div className="room__item-header">
                          <div className="room__item-name">
                            <h3>Spring Field Villa</h3>

                            <p></p>
                            <a href="#" className="custom-btn">
                              <span>
                                <Link to="/deluxe-cottage-in-palghar">
                                  Enquiry Now
                                </Link>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade top>
                <div className="col-lg-4 col-md-6 ">
                  <div className="room__item room__item--style2">
                    <div className="room__item-inner">
                      <div className="room__item-thumb">
                        <img
                          src={room2}
                          alt="Superior Family Room Wooden Cottage"
                        />
                      </div>
                      <div className="room__item-content">
                        <div className="room__item-header">
                          <div className="room__item-name">
                            <h3>
                              <a href="#">
                              Sunny Side Villa
                              </a>
                            </h3>
                            <a href="#" className="custom-btn">
                              <span>
                                <Link to="/best-cottage-for-family-in-palghar">
                                  Enquiry Now
                                </Link>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade left>
                <div className="col-lg-4 col-md-6">
                  <div className="room__item room__item--style2">
                    <div className="room__item-inner">
                      <div className="room__item-thumb">
                        <img
                          src={room3}
                          alt="Superior Family Room -Wooden Cottage"
                        />
                      </div>
                      <div className="room__item-content">
                        <div className="room__item-header">
                          <div className="room__item-name">
                            <h3>
                              <a href="#">
                                Kaashi Villa{" "}
                              </a>
                            </h3>
                            <a href="#" className="custom-btn">
                              <span>
                                <Link to="/cottage-for-family-in-palghar">
                                  Enquiry Now
                                </Link>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                <a href="#" className="custom-btn">
                  <span>
                    <Link to="/resort-near-palghar">View More</Link>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="feature padding-top padding-bottom bg--section-color">
        <div className="container">
          <div
            className="section-header aos-init"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="section-header__content">
              <h2>Kinara resort Your Excellent Choice For Vacation.</h2>
              <p>
                Experience the perfect blend of serenity and excitement at
                Kinara Beach Resort, making it your ultimate vacation
                destination.
              </p>
            </div>
          </div>
          <div className="feature__wrapper">
            <div className="row g-4">
              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img src={h1} alt="Ac room" className="imagesize" />
                      </div>
                      <div className="feature__item-content">
                        <h5>AC</h5>
                        <p>Ac room</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>
              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-delay={200}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img src={h2} alt="Hot Geyse" className="imagesize" />
                      </div>
                      <div className="feature__item-content">
                        <h5> Hot Geyser</h5>
                        <p>Hot Geyser</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>
              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-delay={300}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img
                          src={h3}
                          alt="Swimming Pool"
                          className="imagesize"
                        />
                      </div>
                      <div className="feature__item-content">
                        <h5>Swimming Pool</h5>
                        <p> Swimming Pool</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>
              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-delay={400}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img src={h4} alt="Garden Area" className="imagesize" />
                      </div>
                      <div className="feature__item-content">
                        <h5>Garden Area</h5>
                        <p>Garden Area</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>
            </div>
            <div className="row g-4 padding-20">
              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img src={h5} alt="Games" className="imagesize" />
                      </div>
                      <div className="feature__item-content">
                        <h5>Games</h5>
                        <p>Games</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>
              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-delay={200}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img
                          src={h6}
                          alt="Free Wifi / Cctv"
                          className="imagesize"
                        />
                      </div>
                      <div className="feature__item-content">
                        <h5> Free Wifi / Cctv</h5>
                        <p> free Wifi/Cctv</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>

              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-delay={300}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img
                          src={h7}
                          alt="Free Parking"
                          className="imagesize"
                        />
                      </div>
                      <div className="feature__item-content">
                        <h5> Free Parking</h5>
                        <p> Free Parking</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>

              <div className="col-xl-3 col-md-6">
                <Bounce>
                  <div
                    className="feature__item aos-init"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-delay={400}
                  >
                    <div className="feature__item-inner">
                      <div className="feature__item-thumb">
                        <img
                          src={beach}
                          alt="Beach Access"
                          className="imagesize"
                        />
                      </div>
                      <div className="feature__item-content">
                        <h5>Beach Access</h5>
                        <p>Beach Access</p>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial padding-top padding-bottom">
        <div className="container">
          <div
            className="section-header aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="section-header__content">
              <h2>Our Customer say About Kinara resort</h2>
              <p>
                Discover what our valued customers have to say about their
                unforgettable stays at Kinara Beach Resort.
              </p>
            </div>
          </div>
          <div
            className="testimonial__slider swiper swiper-initialized swiper-horizontal swiper-backface-hidden aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div
              className="swiper-wrapper"
              id="swiper-wrapper-10f34fe96bd622710"
              aria-live="off"
              style={{
                transitionDuration: "0ms",
              }}
            >
              <Testimonial></Testimonial>
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="acorder">
          <div className="container">
            <div className="row">
              <Fade left>
                <div className="col-md-6">
                  <img src={bann2} />
                </div>
              </Fade>
              <Fade right>
                <div className="col-md-6">
                  
                     
                    <div style={{backgroundColor:"#e0d009",padding:"10px"}} >Show Summery</div>
                    <Accordion>
                   
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Location</Accordion.Header>
                            <Accordion.Body>
                              <p style={{ textAlign: "justify" }}>
                                Beach Resort for Family in Palghar offers a
                                prime location that seamlessly combines the
                                tranquility of a beachfront oasis with easy
                                accessibility. Situated just a short drive from
                                the heart of Palghar town, our resort provides a
                                peaceful escape from the bustle of city life,
                                all while being conveniently close to local
                                attractions and transportation hubs. Our
                                resort's location promises a perfect blend of
                                natural beauty and convenience for an
                                unforgettable getaway.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                       

                      
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Cleanlines</Accordion.Header>
                            <Accordion.Body className="justifycontent">
                              <p style={{ textAlign: "justify" }}>
                                Beach Resort for Family in Palghar prioritizes
                                your health and safety. Our dedicated
                                housekeeping team maintains rigorous cleanliness
                                protocols to ensure every corner of our property
                                is spotless. From sanitized rooms to sanitized
                                common areas, we go above and beyond to create a
                                pristine environment, giving you the peace of
                                mind you deserve during your stay. Your
                                well-being is our top priority, and our
                                commitment to cleanliness is unwavering.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        
                        
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>Comfort</Accordion.Header>
                            <Accordion.Body className="justifycontent">
                              <p style={{ textAlign: "justify" }}>
                                Beach Resort for Family in Palghar is designed
                                to ensure your utmost comfort, as
                                accommodations, plush amenities, and attentive
                                service create an oasis of relaxation, making
                                your stay truly exceptional. From cozy rooms
                                with breathtaking views to spacious suites that
                                redefine comfort, we prioritize your well-being,
                                ensuring you feel at home from the moment you
                                arrive.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                          
                         
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>Staff</Accordion.Header>
                            <Accordion.Body className="justifycontent">
                              <p style={{ textAlign: "justify" }}>
                                Beach Resort for Families in Palghar is
                                committed to ensuring your every need is met and
                                your stay is truly exceptional. From warm
                                welcomes at check-in to attentive service
                                throughout your visit, our team is here to make
                                your experience unforgettable. We take pride in
                                delivering genuine hospitality, ensuring that
                                you leave with fond memories and a desire to
                                return.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                          
                      
                       
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>Value Of Money</Accordion.Header>
                            <Accordion.Body className="justifycontent">
                              <p style={{ textAlign: "justify" }}>
                                Beach Resort for Family in Palghar experiences
                                the utmost value for your money, as our
                                commitment to affordability and excellence
                                ensures that every moment of your stay is worth
                                every penny. From luxurious accommodations to
                                top-notch amenities and exceptional service, we
                                make sure you get the most out of your
                                investment in a remarkable getaway.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                       
                       
                       
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>Facility</Accordion.Header>
                            <Accordion.Body className="justifycontent">
                              <p style={{ textAlign: "justify" }}>
                                Beach Resort for Family in Palghar explores the
                                extensive range of facilities carefully curated
                                to enhance your stay. From gourmet dining to
                                family-friendly activities, we offer everything
                                you need for a memorable and fulfilling vacation
                                experience. Our attentive staff is dedicated to
                                ensuring your every need is met, making your
                                stay with us truly exceptional.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                          </Accordion>
                  
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <h1 style={{ display: "none" }}>Beach Resort For Family in Palghar </h1>
      <Footer></Footer>
    </div>
  );
};

export default Home;
