import React, { useState, useCallback } from "react";
import Hadder from '../components/Hadder'
import Footer from '../components/Footer'

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../components/photos";
import ban5 from "../assets/Banner/ban5.JPG"
import { MetaTags } from 'react-meta-tags';
const Gallery1 = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
        <Hadder></Hadder>
        <MetaTags>
        <title>Resort In Palghar | Kinara Beach Resort</title>
        <meta title="Resort In Palghar | Kinara Beach Resort" />
        <meta name="description" content="Discover your haven of relaxation at our resort in Palghar. Surrounded by natural beauty, our tranquil retreat offers comfortable accommodations, modern amenities, and a serene atmosphere for your ideal getaway." />
        <meta name="keywords" content="beach-resort-family-palghar, beach-resort-near-palghar,beach-resort-in-palghar,resort-in-palghar,resort-near-palghar,top-beach-resort-in-palghar,deluxe-cottage-in-palghar,best-cottage-for-family-in-palghar,cottage-for-family-in-palghar,cottage-for-couple-in-palghar" />
        <link rel="canonical" href="https://kinararesort.in/resort-in-palghar" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Resort In Palghar | Kinara Beach Resort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://kinararesort.in/" />
        <meta property="og:description" content="Discover your haven of relaxation at our resort in Palghar. Surrounded by natural beauty, our tranquil retreat offers comfortable accommodations, modern amenities, and a serene atmosphere for your ideal getaway." />
        <meta property="og:image" content="https://kinararesort.in/static/media/logo.146c55d2a549f20e2963.png" />
      </MetaTags>
        <section className="page-header bg--cover second-banner" style={{backgroundImage:`url(${ ban5})`}}>
        <div className="container">
          <div className="page-header__content text-center">
            <h1 style={{color:"white"}}>Resort In Palghar</h1>
           
              
          
          </div>
        </div>
      </section>
      <section className=" mb-5">
      <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
    </section>
      
      <Footer></Footer>
    </div>
  )
}

export default Gallery1
