import React from 'react'
import "../css/style.css"
import logo from "../assets/logo.png"
import s1 from "../assets/s1.jpg"
import s2 from "../assets/s2.jpg"
import s3 from "../assets/s3.jpg"
import s4 from "../assets/s4.jpg"
import s5 from "../assets/s5.jpg"
import s6 from "../assets/s6.webp"
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div>
      <div className="footer__wrapper ">
  <div className="container">
    <div className="row g-5">
      {/* footer about */}
      <div className="col-lg-3 col-sm-6">
        <div className="footer__about" style={{textAlign:"left"}}>
          <a className="footer__brand">
            <img src={logo} style={{width:"70%"}} alt="Tavern logo" />
          </a>
          <p>
          Get in touch with us easily through our Contact Us. We're here to assist you and address your inquiries .
          </p>
       
        </div>
      </div>
      {/* useful links */}
      <div className="col-lg-3 col-sm-6">
        <div className="footer__block" style={{textAlign:"left"}}>
          <div className="footer__block-title">
            <h3>Useful Links</h3>
          </div>
          <div className="footer__block-body" style={{textAlign:"left"}}>
            <ul className="footer__links">
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  <span>
                
                    {/* <i class="fa-solid fa-arrow-right"></i> Font Awesome fontawesome.com */}
                  </span>{" "}
                  <Link to="/">Home</Link>
                  
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  <span>
                   
                    {/* <i class="fa-solid fa-arrow-right"></i> Font Awesome fontawesome.com */}
                  </span>
                  <Link to="/beach-resort-near-palghar">AboutUs</Link>
                  
                </a>
              </li>
              <li className="footer__links-item">
                {" "}
                <a className="footer__link" href="#">
                  <span>
               
                    {/* <i class="fa-solid fa-arrow-right"></i> Font Awesome fontawesome.com */}
                  </span>
                  <Link to="/beach-resort-in-palghar">Service</Link>
                  
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  <span>
             
                    {/* <i class="fa-solid fa-arrow-right"></i> Font Awesome fontawesome.com */}
                  </span>
                  <Link to="/resort-near-palghar">Rooms</Link>
                  
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  <span>
                   
                    {/* <i class="fa-solid fa-arrow-right"></i> Font Awesome fontawesome.com */}
                  </span>
                  <Link to="/resort-in-palghar"> Gallery</Link>
               
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  <span>
                   
                    {/* <i class="fa-solid fa-arrow-right"></i> Font Awesome fontawesome.com */}
                  </span>
                  <Link to="/top-beach-resort-in-palghar"> Contact Us</Link>
               
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Contact infos */}
      <div className="col-lg-3 col-sm-6">
        <div className="footer__block" style={{textAlign:"left"}}>
          <div className="footer__block-title">
            <h3>Contact info</h3>
          </div>
          <div className="footer__block-body">
            <ul className="footer__info">
              <li className="footer__info-item">
                <span>
                  <svg
                    className="svg-inline--fa fa-phone"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="phone"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
                    />
                  </svg>
                  {/* <i class="fa-solid fa-phone"></i> Font Awesome fontawesome.com */}
                </span>
                <p> +91 8830633323<br></br>+91 7378580242</p>
              </li>
              <li className="footer__info-item">
                <span>
                  <svg
                    className="svg-inline--fa fa-clock"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="clock"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
                    />
                  </svg>
                  {/* <i class="fa-solid fa-clock"></i> Font Awesome fontawesome.com */}
                </span>
                <p>Check In Time - 1:00 pm    Check Out Time - 11:00 am </p>
              </li>
              <li className="footer__info-item">
                <span>
                  <svg
                    className="svg-inline--fa fa-location-dot"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="location-dot"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                    />
                  </svg>
                  {/* <i class="fa-solid fa-location-dot"></i> Font Awesome fontawesome.com */}
                </span>
                <p>Kinara agro tourism, near kamilsha stop,at post satpati,satpati road,palghar 401405 </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Gallery */}
      <div className="col-lg-3 col-sm-6">
        <div className="footer__block" style={{textAlign:"left"}}>
          <div className="footer__block-title">
            <h3>Location</h3>
          </div>
          <div className="footer__block-body">
          <div style={{borderRadius:"25px"}}>
              <iframe style={{borderRadius:"25px"}}
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d481642.72603758593!2d72.48393007886355!3d19.41958581852892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be6e390716fce3b%3A0x15687b5258e3f461!2sKinara%20Agro%20tourism%2Csatpati!5e0!3m2!1sen!2sin!4v1693230060698!5m2!1sen!2sin"/>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className='Copy-Right' style={{color:"black"}}>
                    <div className='Contact-Us-Copy'>
                        © Copyright
                        <strong>
                            <span style={{ color: "green" }}> Kinara Resort </span>
                        </strong>
                        . All Rights Reserved
                    </div>
                    <div className="credits">
                        Designed by  
                        <a href="https://skdm.in/" style={{ color: "green" }}>
                             Shree Krishna Digital
                        </a>
                    </div>
                </div>
    </div>
  )
}

export default Footer
