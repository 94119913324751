import React from 'react'
import Hadder from '../components/Hadder'
import Footer from '../components/Footer'
import "../css/Service.css"
import ban5 from "../assets/Banner/ban5.JPG"
import s1 from "../assets/s1.jpg"
import s2 from "../assets/s2.jpg"
import s3 from "../assets/s3.jpg"
import s4 from "../assets/s4.jpg"
import s5 from "../assets/s5.jpg"
import s6 from "../assets/s6.webp"
import s7 from "../assets/s7.jpg"
import s8 from "../assets/s8.jpg"
import s9 from "../assets/s9.webp"
import s10 from "../assets/s10.jpg"
import res from "../assets/Home/SERVICE/RESTORENT.JPG"
import Bounce from 'react-reveal/Bounce';
import { MetaTags } from 'react-meta-tags';
const Service = () => {
  return (
    <div>
        <Hadder></Hadder>
        <MetaTags>
        <title>Beach Resort In Palghar | Kinara Beach Resort</title>
        <meta title=" Beach Resort In Palghar | Kinara Beach Resort" />
        <meta name="description" content="Experience beachfront bliss at our Palghar Beach Resort. Immerse yourself in nature's beauty with stunning ocean views, luxurious accommodations, and a tranquil escape for the perfect coastal getaway." />
        <meta name="keywords" content="beach-resort-family-palghar, beach-resort-near-palghar,beach-resort-in-palghar,resort-in-palghar,resort-near-palghar,top-beach-resort-in-palghar,deluxe-cottage-in-palghar,best-cottage-for-family-in-palghar,cottage-for-family-in-palghar,cottage-for-couple-in-palghar" />
        <link rel="canonical" href="https://kinararesort.in/beach-resort-in-palghar" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Beach Resort In Palghar | Kinara Beach Resort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://kinararesort.in/" />
        <meta property="og:description" content="Experience beachfront bliss at our Palghar Beach Resort. Immerse yourself in nature's beauty with stunning ocean views, luxurious accommodations, and a tranquil escape for the perfect coastal getaway." />
        <meta property="og:image" content="https://kinararesort.in/static/media/logo.146c55d2a549f20e2963.png" />
      </MetaTags>
        <section className="page-header bg--cover second-banner" style={{backgroundImage:`url(${ ban5})`}}>
        <div className="container">
          <div className="page-header__content text-center">
            <h1 style={{color:"white"}}>Beach Resort In Palghar</h1>
           
             
          
          </div>
        </div>
      </section>
      <section className="service padding-top padding-bottom">
  <div className="container">
    <div className="section-header">
      <div className="section-header__content">
        <h2>Kinara resort Your Excellent Choice For Vacation.</h2>
        <p>
          Continually productize compelling quality for packed in business
          consulting elated Setting up to website and creating pages.
        </p>
      </div>
    </div>
    <>
  <div className="dd_heading">
    
  </div>
  <main className="page-content">
  <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading">Restaurant</h2>
        <p className="data-content">
        Food that Speaks to the Soul.{" "}
        </p>
      </div>
    </div>
    </Bounce>
    <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading">Open lawn area</h2>
        <p className="data-content">
        Where Nature and Celebration Unite.{" "}
        </p>
      </div>
    </div>
    </Bounce>
    <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading">Beach access</h2>
        <p className="data-content">
        Your Gateway to Seaside Bliss.{" "}
        </p>
      </div>
    </div>
    </Bounce>
    <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading">Wifi across resort</h2>
        <p className="data-content">
        Stay Connected, Even in Paradise.{" "}
        </p>
      </div>
    </div>
    </Bounce>
    <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading">Rain dance</h2>
        <p className="data-content">
        Dance Under the Rain, Make Memories that Remain.{" "}
        </p>
      </div>
    </div>
    </Bounce>
    <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading">Fountain on swimming pool</h2>
        <p className="data-content">
        Experience the joy of dancing in the rain at our exhilarating rain dance event.{" "}
        </p>
      </div>
    </div>
    </Bounce>
    <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading">Mushroom on swimming pool</h2>
        <p className="data-content">
        Dive into Luxury, Where Fountains Dance to Life.{" "}
        </p>
      </div>
    </div>
    </Bounce>
    <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading">Baby pool</h2>
        <p className="data-content">
        Tiny Splashes, Big Smiles in Our Baby Pool.{" "}
        </p>
      </div>
    </div>
    </Bounce>
    <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading">Candle light decor</h2>
        <p className="data-content">
        Elegant Ambiance by Candlelight.{" "}
        </p>
      </div>
    </div>
    </Bounce>
    <Bounce>
    <div className="d_card">
      <div className="content">
        <h2 className="heading">Room decor for birthday party</h2>
        <p className="data-content">
        Transforming Rooms into Celebration Dreams.{" "}
        </p>
      </div>
    </div>
    </Bounce>
  </main>
  <main className="page-content">
    
  </main>
</>

 
  </div>
</section>
<Footer></Footer>

    </div>
  )
}

export default Service
