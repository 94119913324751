import React, { useState } from 'react';
import { Nav, Navbar, Container } from "react-bootstrap";
import hadder from "../css/hadder.css"
import Logo from "../assets/logo.png"
import { Link } from "react-router-dom";



const Hadder = () => {
    
  return (
    <>
    <div className="nav-bar-component">
        <section className="NavSection">
            {/* <div className="container"> */}
            <Navbar collapseOnSelect expand="lg" variant="dark" >
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Brand href="#home">
                        <div className='NavLogo'>
                             <img src={Logo}  alt=" kinara-resort logo" /> 
                        </div>
                    </Navbar.Brand>
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-start">
                        <Nav>
                            <Nav.Link href="#"> <Link to="/">Home</Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/beach-resort-near-palghar">About us </Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/beach-resort-in-palghar">Service</Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/resort-near-palghar">Rooms</Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/resort-in-palghar">Gallery</Link></Nav.Link>
                            
                            <Nav.Link><Link to="/top-beach-resort-in-palghar">Contact Us</Link></Nav.Link>
                            
                          
                        </Nav>
                    </Navbar.Collapse>
                    <Nav className="Social-media-icons">
                    </Nav>
                </Container>
            </Navbar>
            {/* </div> */}
        </section>

    </div>
</>
  )
}

export default Hadder
