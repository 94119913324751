import React from 'react'
import Hadder from './Hadder'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import "../css/error.css"
const Error = () => {
  return (
    <div>
        <Hadder/>
      <div className='Errorpage'>
                <div class='error'>
                    <h1 class='code' style={{color:"#e0d009"}}>404</h1>
                    <h2 class='desc' style={{marginTop:"60px",color:"white"}}>Opss... There's something wrong.</h2>
                    <Link to="/" style={{color:"white"}}> 
                        ... Back to previous page
                    </Link>
                </div>
            </div>
            <Footer/>
    </div>
  )
}

export default Error
